import { Role } from '../shared/_models/role';

export const defaultInterviewStatusColor = '#898f89';
export const defaultCandidateStatusColor = '#898f89';

export const red50 = 'rgba(255, 0, 0, 0.5)';
export const black = '#000000';
export const white = '#ffffff';
var date = new Date();
import * as dayjs from 'dayjs';

export const interviewStatusColors = [
  {
    status: 'NEW', //Upcoming
    color: '#3faaff',
    fontColor: '#fff',
  },
  {
    status: 'CMP', //Completed
    color: '#1b8c03',
    fontColor: '#fff',
  },
  {
    status: 'CAN', //Cancelled
    color: '#ff0000',
    fontColor: '#fff',
  },
  {
    status: 'RTD', //Rated
    color: '#E5BD00',
    fontColor: '#000',
  },
  {
    status: 'NS', //No Show
    color: '#ff0000',
    fontColor: '#fff',
  },
  {
    status: 'AR', //Awaiting Rating
    color: '#ffeb00',
    fontColor: '#000',
  },
];

export const candidateStatusColors = [
  {
    status: 'N',
    bgcolor: '#3faaff',
    color: '#fff',
  },
  {
    status: 'S',
    bgcolor: '#00a400',
    color: '#fff',
  },
  {
    status: 'J',
    bgcolor: '#00a400',
    color: '#fff',
  },
  {
    status: 'RJ',
    bgcolor: '#ff0000',
    color: '#fff',
  },
  {
    status: 'RJI',
    bgcolor: '#ff0000',
    color: '#fff',
  },
  {
    status: 'D',
    bgcolor: '#ff0000',
    color: '#fff',
  },
  {
    status: 'OR',
    bgcolor: '#ff0000',
    color: '#fff',
  },
  {
    status: 'E',
    bgcolor: '#ffeb00',
    color: '#000',
  },
];

export const filterItems = [
  {
    displayName: 'My Interviews',
    dropdownKey: 'name',
    type: 'single-select',
    source: 'custom',
    id: 'view_only_my_interviews',
    apiKey: 'view_only_my_interview',
    priority: 0,
  },
  {
    displayName: 'Interview Status',
    dropdownKey: 'name',
    type: 'multi-select',
    source: 'dropdown-api',
    id: 'status',
    apiKey: 'Interview_status_choice',
    priority: 1,
  },
  {
    displayName: 'Scheduled by',
    dropdownKey: 'email',
    displayTag: 'Scheduled By',
    filterDisplayKey: 'username',
    type: 'search-multi-select',
    source: 'dropdown-api',
    id: 'scheduled_by',
    apiKey: 'HR_users',
    placeholder: 'Search Scheduled user',
    priority: 2,
    role: [Role.Director, Role.HR, Role.HRM],
  },
  {
    displayName: 'Scheduled For',
    dropdownKey: '',
    type: 'date-range',
    source: 'dropdown-api',
    id: 'sch_date',
    id_1: 'scheduled_for_from',
    id_2: 'scheduled_for_to',
    apiKey: '',
    priority: 3,
    min_date: dayjs.Dayjs,
    max_date: dayjs.Dayjs,
  },
  {
    displayName: 'Interviewer',
    dropdownKey: 'email',
    displayTag: 'Interviewer',
    filterDisplayKey: 'username',
    type: 'search-multi-select',
    source: 'dropdown-api',
    id: 'lead_interviewer',
    apiKey: 'Interviewers',
    placeholder: 'Search Interviewer',
    priority: 4,
  },
  {
    displayName: 'Interview Level',
    dropdownKey: 'name',
    type: 'multi-select',
    source: 'dropdown-api',
    id: 'level',
    apiKey: 'Interview_level_choice',
    priority: 5,
  },
  {
    displayName: 'Interview Type',
    dropdownKey: 'name',
    type: 'multi-select',
    source: 'dropdown-api',
    id: 'mode',
    apiKey: 'Interview_mode_choice',
    priority: 6,
  },
  {
    displayName: 'Interview Location',
    dropdownKey: 'name',
    type: 'multi-select',
    source: 'dropdown-api',
    id: 'location',
    apiKey: 'Interview_Location',
    priority: 7,
  },
  {
    displayName: 'Department',
    dropdownKey: 'name',
    type: 'multi-select',
    source: 'dropdown-api',
    id: 'department',
    apiKey: 'Department',
    priority: 8,
  },
  {
    displayName: 'Stream',
    dropdownKey: 'name',
    type: 'multi-select',
    source: 'dropdown-api',
    id: 'stream',
    apiKey: 'Stream',
    priority: 9,
  },
  {
    displayName: 'Candidate Type',
    dropdownKey: 'name',
    type: 'multi-select',
    source: 'custom',
    id: 'candidate_type',
    apiKey: 'CandidateTypes',
    priority: 10,
  },
  {
    displayName: 'Show Past Data',
    dropdownKey: 'name',
    displayTag: 'Show Past Data',
    type: 'dropdown-select',
    dropdownName: 'Select Year',
    source: 'dropdown-api',
    id: 'show_past_data',
    apiKey: 'Year_wise_data',
    priority: 11,
    role: [Role.Director, Role.HR, Role.HRM],
  },
];

export const customData = {
  CandidateTypes: [
    {
      id: 'fresher',
      name: 'Freshers',
    },
    {
      id: 'lateral',
      name: 'Laterals',
    },
  ],
  NotYetRated: [
    {
      id: true,
      name: 'Not Yet Rated',
    },
  ],
  view_only_my_interview: [
    {
      id: true,
      name: 'My Interviews',
    },
  ],
};

export const sortBy = [
  {
    displayName: 'Interview Date',
    id: 'scheduled_for',
  },
  {
    displayName: 'Overall Rating',
    id: 'interviewrating__overall',
  },
  {
    displayName: 'Last updated on',
    id: 'last_updated_at_qrms',
  },
];

export const interviewActions = [
  {
    id: 'edit',
    icon: './assets/images/edit-bg-rounded.svg',
    label: 'Edit Interview',
    isAllowed: false,
  },
  // {
  //   id: 'call_letter',
  //   icon: './assets/images/mail-blue.svg',
  //   label: 'Send Call Letter',
  //   isAllowed: false,
  // },
  {
    id: 'cancel',
    icon: './assets/images/close.svg',
    label: 'Cancel Interview',
  },
  {
    id: 'reqest-rating',
    icon: './assets/images/request-rating.svg',
    label: 'Request Rating',
  },
];

export const scheduleInvitesStatusColors = {
  'Awaiting Schedule' : '#555555',
  'Slot Booked': '#1b8c03',
  'Invite Expired': '#ff0000',
  'Invite Revoked': '#999999',
  'Invite Declined': '#ff0000',
  'Requested slot': '#f4ff2d'
}
