

export const red50 = 'rgba(255, 0, 0, 0.5)';
export const greytext = '#4c4c4c';
export const defaultCandidateStatusColor = '#898f89';
export const white = '#fff';

export const candidateStatusColors = [
  {
    status: 'New',
    bgcolor: '#42a7fe',
    color: '#fff'
  }, {
    status: 'Selected',
    bgcolor: '#00a400',
    color: '#fff'
  }, {
    status: 'Rejected',
    bgcolor: '#ff0000',
    color: '#fff'
  }, {
    status: 'Eligible for next round',
    bgcolor: '#ffeb00',
    color: '#000'
  }, {
    status: 'Declined',
    bgcolor: '#ff0000',
    color: '#fff'
  }, {
    status: 'Offer revoked',
    bgcolor: '#ff0000',
    color: '#fff'
  }, {
    status: 'Rejected after interview',
    bgcolor: '#ff0000',
    color: '#fff'
  },
]

export const noticePeriodTypes = [
  {
    id: 'S',
    name: 'Serving Notice Period'
  },
  {
    id: 'I',
    name: 'Immediate'
  },
  {
    id: 'R',
    name: 'Regular'
  },
]

export const scheduleInterviewStatus = [
  "New",
  "Shortlisted",
  "Interview",
  "On hold after interview",
  "Eligible for next round",
  "Withdrawn",
  "Hiring Recommended",
  "CV on Hold"
];
export const candidateActions = [
  {
    id: 'salary_doc',
    label: 'Request salary documents',
    isAllowed: false,
    confirmationMessage: "Are you sure you want to request salary document?",
    isHidden: true
  },
  {
    id: 'offer_letter',
    label: 'Send offer letter',
    isAllowed: false
  },
  {
    id: 'profile_creation',
    label: 'Send profile creation mail',
    isAllowed: false
  },
  {
    id: 'download_offer',
    label: 'Download offer letter',
    isAllowed: false
  },
  {
    id: 'download_toe',
    label: 'Download terms of employment',
    isAllowed: false,
    isHidden: true,
    role:[]
  },
  {
    id: 'feedback_email',
    label: 'Send feedback email',
    isAllowed: false,
    isHidden: true
  },
]

export const OfferLetterRequiredFields = [
  'address',
  'employment_type',
  'designation',
  'hiring_approved_by',
  'joining_bonus',
  'joining_date',
  'joining_location',
  'offered_salary',
  'onboarding_type',
  'remote',
  'remote_location'
]

export const offerActions = [
  {
    id: 'send_offer',
    label: 'Send Offer',
    isAllowed: true
  },
  {
    id: 'revoke_offer',
    label: 'Revoke Offer',
    isAllowed: true
  },
]