import { Injectable } from '@angular/core';
import { ApiService } from './../core/http/api.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, shareReplay, map } from 'rxjs/operators';
import { JobPost } from '../shared/_models/job-post';

export const routes = {
  getJobPost: `/api/job-posts/`,
  getJobPostById: `/api/job-posts`,
  addJobPost: `/api/job-posts/`,
  updateJobPost: `/api/job-posts/`,
  listDepartments: `/api/departments/`,
  listJobLocations: `/api/job-locations/`,
  listSpaceUsers: `/api/space-users/`,
  getDropdowns: `/api/dropdown-items/`,
  jobPostComment: `/api/job-post/comments/`,
  agencies: `/api/job-posts/<int:jobpost_id>/agencies/`,
}

@Injectable({
  providedIn: 'root'

})

export class JobPostService {
  private currentJobPostSubject: BehaviorSubject<JobPost>;
  public currentJobPost: Observable<JobPost>;
  private dropdownsSubject = new BehaviorSubject<void>(void 0);
  private taggedCandidateCountUpdate = new BehaviorSubject<boolean>(false);
  taggedCandidateCount = this.taggedCandidateCountUpdate.asObservable();
  reloadJobPostContents = new Subject<void>();
  shouldReload: boolean = false;


  constructor(private apiService: ApiService) {
    this.currentJobPostSubject = new BehaviorSubject<JobPost>({});
    this.currentJobPost = this.currentJobPostSubject.asObservable();
  }

  public get currentJobPostValue(): JobPost {
    return this.currentJobPostSubject.value;
  }

  getTaggedCandidateCountUpdated(val:boolean = false) {
    this.taggedCandidateCountUpdate.next(val);
  }

  getJobPostById(id: number): Observable<any> {
    return this.apiService.get(`${routes.getJobPostById}/${id}/`);
  }

  getAgenciesByJobId(id: number): Observable<any> {
    return this.apiService.get(`${routes.getJobPostById}/${id}/agency/`);
  }

  getJobPostList(url: string, params?: any) {
    return this.apiService.get(url, params)
  }

  createJobPost(data: any) {
    return this.apiService.post(routes.addJobPost, data);
  }

  updateJobPostPatch(id: any, data: any) {
    return this.apiService.patch(`${routes.updateJobPost}${id}/status/`, data);
  }

  updateJobPost(id: number, data: any) {
    return this.apiService.put(`${routes.addJobPost}${id}/`, data);
  }

  publishJobPost(id: number) {
    return this.apiService.patch(`${routes.getJobPostById}/${id}/publish/`, {});
  }

  editJobPost(id: number, data: any) {
    return this.apiService.put(`${routes.addJobPost}${id}/`, data);
  }
  
  getJobComments(id: any) {
    return this.apiService.get(`${routes.getJobPost}${id}/comments/`);
  }

  addJobComments(id: any, data: any) {
    return this.apiService.post(`${routes.getJobPost}${id}/comments/`, data);
  }

  getDepartmentData(data: any): Observable<any> {
    return this.apiService.get(routes.listDepartments, data);
  }

  getJobLocations(data: any): Observable<any> {
    return this.apiService.get(routes.listJobLocations, data)
  }

  getSpaceUsers(data: any): Observable<any> {
    return this.apiService.get(routes.listSpaceUsers, data)
  }

  getGeneralDropdowns() {
    return this.apiService.get(routes.getDropdowns);
  }

  readonly getDepartmentList = this.dropdownsSubject.pipe(
    switchMap(
      () => this.apiService.get(routes.listDepartments)
        .pipe(
          map((response: any) => response.data)
        )
    ),
    shareReplay(1)
  );

  readonly getjobLocationList = this.dropdownsSubject.pipe(
    switchMap(
      () => this.apiService.get(routes.listJobLocations)
        .pipe(
          map((response: any) => response.data)
        )
    ),
    shareReplay(1)
  );

  readonly getspaceUserList = this.dropdownsSubject.pipe(
    switchMap(
      () => this.apiService.get(routes.listSpaceUsers)
        .pipe(
          map((response: any) => response.data)
        )
    ),
    shareReplay(1)
  );

  approveJobPost(id: any, data: any) {
    return this.apiService.put(`${routes.updateJobPost}${id}/approve/`, data);
  }

  deleteJobPostComment(id: any) {
    return this.apiService.delete(`${routes.jobPostComment}${id}/`);
  }

  getJobPostAgencies(id: string, params?: any): Observable<any> {
    return this.apiService.get(`/api/job-posts/${id}/agency/`, params);
  }

  updateJobPostAgencies(id: string, data: any) {
    return this.apiService.post(`/api/job-posts/${id}/agency/`, data);
  }

  getDiscoverCandidates(id: any, params: any) {
    return this.apiService.get(`${routes.getJobPost}${id}/discover/`,  params);
  }

  getTaggedCandidates(id: any, params: any) {
    return this.apiService.get(`${routes.getJobPost}${id}/tagged-candidates/`, params);
  }

  sendTagToJobAction(id:any, data:any){
    return this.apiService.post(`${routes.getJobPost}${id}/tag/`, data);
  }
  
  getJobPostHistory(jobPostId: any, page: any) {
    return this.apiService.get(
      `${routes.getJobPostById}/${jobPostId}/history/?page=${page}`
    );
  }

  updateCandidateFit(id: string, data: any) {
    return this.apiService.post(`/api/job-posts/${id}/feedback/`, data);
  }
}
