
export const filterItems = [
  {
    displayName: 'Stream',
    dropdownKey: "name",
    type: "filter-multi-select",
    source: "dropdown-api",
    id: "stream",
    apiKey: "Stream",
    placeholder: "Search Stream",
    filterDisplayKey: "name",
    priority: 1
  },
];



export const sortBy = [
  {
    displayName: 'Invite Sent On',
    id: 'sent_on',
  },
];

export const availabilityColorCode = [
  {
    level: 'very low',
    color: '#ed202a'
  },
  {
    level: 'low',
    color: '#ffa700'
  },
  {
    level: 'medium',
    color: '#f4c500'
  }
];